.sign-out {
  .authentication-button {
    width: 100%;
    min-height: 3.5rem;

    .p-button-icon {
      display: none;
    }
  }
  .retry-message-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      color: var(--primary-color) !important;
    }
  }
}
