.p-inputgroup-number {
  position: relative;
  .p-inputnumber {
    flex-grow: 1;
  }
  .silder-number {
    position: absolute;
    bottom: -0.25rem;
    left: 0;
    right: 0;
  }
  .p-inputnumber-button {
    background: var(--surface-c);
    border: transparent;
    color: var(--surface-e);
    right: 0;
    z-index: 9999;
    &.p-inputnumber-button-up {
      top: 0;
      height: 50%;
    }
    &.p-inputnumber-button-down {
      bottom: 0;
      height: 50%;
    }
  }
  .control-hint-bottom {
    display: inline-block;
    margin-top: 0.25rem;
  }

  .control-hint-right {
    display: flex;
    align-items: center;
    margin: 0 0 0 1rem;
  }
}
