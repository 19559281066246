@import "../../style/utils";

.main-content {
  padding: 1.8rem 1.6rem;
  height: calc(100vh - 82px);
  overflow: auto;
  &.open-sidebar {
    margin-left: 22rem;
    @media screen and (min-width: 769px) {
      &.push-margin {
        margin-left: 34rem;
      }
    }
    @include sm-screen {
      margin-left: 0rem;
    }
    .ep-login-footer {
      position: relative;
      display: contents;
    }
  }
  &.close-sidebar {
    margin-left: 3rem;
    @include sm-screen {
      margin-left: 0rem;
    }
  }
  @include sm-screen {
    padding-top: calc(82px + 0.5rem);
    height: unset;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media screen and (min-width: 769px) {
    .home-left-panel {
      padding: 0 14px 10px 14px;
    }

    .home-right-panel {
      padding: 0 14px 10px 14px;
    }
  }
}
