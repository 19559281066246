@import "./../../../../../../style/utils/index.scss";

.entity-display-name {
  flex-wrap: nowrap !important;

  .logo-container {
    max-width: 42px;
    max-height: 42px;
    height: 100%;
    width: 100%;

    .logo-size {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 0% 0%;
    }
  }
}

.display-name-mobile {
  font-weight: bold;
  font-size: 15px;
  width: calc(100vw - 17rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.display-name-desktop {
  font-weight: bold;
  font-size: 15px;
}

.default-logo {
  font-size: 2rem;
}

.main-entity-info {
  .main-entity-info-button {
    padding: 0;
    border: 0;
    background-color: transparent;

    .p-button-label {
      color: black;
      word-break: break-word;
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: black;
      font-weight: 600;
    }

    .p-button-icon {
      color: black;
    }
  }
}

.entity-template {
  align-items: center;
  // .entity-template-container {
  // max-height: 42px;
  // max-width: 42px;
  // align-items: center;
  // }
  .entity-template-icon {
    margin: 0.5rem;
    width: 100%;
    object-fit: cover;
    width: 42px;
  }
}

// .main-entity-info-panel {
//   @include sm-screen {
//     top: 69px !important;
//   }
// }
.main-entity-info-panel {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.main-entity-info-panel > .p-overlaypanel-content > .c-setting-tree {
  max-height: 205px;
  overflow: scroll;
}
