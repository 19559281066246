@import "./../../../../style/utils/";

.p-menubar-button {
  display: none !important;
}

.main-header {
  height: 82px;
  background: white;
  display: contents;
  .p-menubar {
    background: white;
    align-self: start;
    .p-menubar-start {
      margin-right: 1rem;
    }
  }
  /* make it fixed when small screen */
  @include sm-screen {
    position: fixed;
    display: block;
    top: 0;
    width: 100%;
    z-index: 999;
  }
  .main-header-right-content {
    flex-wrap: nowrap;
  }
}

.tenant-logo-container {
  flex-wrap: nowrap !important;
  .tenant-logo-size {
    height: 42px;
  }
}

.tenant-left-header {
  align-items: center;
}
