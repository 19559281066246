@import "../../../style/utils";
.date-control-inner {
  .p-component {
    align-items: center;
  }

  .p-datepicker-month {
    font-size: 1rem;
    padding: 0.25rem;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--surface-d);
    background: var(--surface-c);
  }
  .p-datepicker-year {
    font-size: 1rem;
    padding: 0.25rem;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--surface-d);
    background: var(--surface-c);
  }
  button.p-button.p-component.p-datepicker-trigger {
    background: transparent;
    border: transparent;
    color: var(--surface-e);
    position: absolute;
    right: 0;
    &:hover {
      color: var(--primary-color);
    }
    @include sm-screen {
      display: none;
    }
  }

  .control-hint-bottom {
    display: inline-block;
    margin-top: 0.25rem;
  }

  .control-hint-right {
    display: flex;
    align-items: center;
    margin: 0 0 0 1rem;
  }
}
