.l-side-modal {
  position: relative;
  .bx--modal-container {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    max-height: unset;
  }
  .l-side-modal-header {
    min-height: 5rem;
  }
  .l-side-modal-body {
    min-height: 20rem;
    max-height: calc(100vh - 5rem - 5rem);
    overflow: auto;
  }
  .l-side-modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.api-toast {
  display: flex;
  background: var(--surface-k);
  color: white;
  flex: 1;

  .api-toast-icon {
    height: 26px;
    width: 26px;
    border-radius: 13px;
    background: #3dba61;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--surface-k);
  }

  .api-toast-timestamp {
    padding-top: 15px;
  }

  .p-toast-message-text {
    padding-top: 3px;
  }
}
