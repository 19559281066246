.array-control-table {
  .p-datatable-tbody {
    tr {
      &:first-child {
        padding: 0;
        td {
          padding-bottom: 1.5rem;
          .base-control {
            .p-field {
              margin-bottom: 0rem;
            }
            label {
              display: none;
            }
            margin-left: -0.75rem;
          }
        }
      }
    }
  }
}
