.upload-control {
  .p-fileupload {
    width: 100%;
  }

  .file-upload-container {
    width: 100%;
  }

  .file-upload {
    min-height: 250px;
  }

  .action-column {
    width: 6rem;
    text-align: center !important;
  }

  table {
    thead > tr > th {
      background: var(--surface-d) !important;
    }

    tbody > tr > td {
      background: var(--surface-b) !important;

      .download-button {
        background: none !important;
      }
    }

    .p-datatable-emptymessage > td {
      text-align: center;
    }
  }

  .p-inputgroup
    button:first-child.p-component.p-button-text.custom-input-action-btn.download-button {
    background: none !important;
    color: black;
  }

  .control-hint-bottom {
    display: inline-block;
    margin-top: 0.25rem;
  }

  .control-hint-right {
    display: flex;
    align-items: center;
    margin: 0 0 0 1rem;
  }
}
