.passwordHint {
  padding: 0.5rem;
  font-size: 0.8rem;
  .message {
    position: relative;
    color: red;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      position: relative;
      margin-bottom: 0.25rem;
      i {
        margin-left: 1rem;
        margin-right: 0.5rem;
        position: relative;
        top: 0.125rem;
        &.valid {
          color: green;
        }
        &.invalid {
          color: red;
        }
      }
    }
  }
}
