.text-uppercase {
  text-transform: uppercase;
}

.input-control-inner {
  .control-hint-bottom {
    display: inline-block;
    margin-top: 0.25rem;
  }

  .control-hint-right {
    display: flex;
    align-items: center;
    margin: 0 0 0 1rem;
  }

  &.p-field {
    margin-bottom: 0.5rem;
    & > label {
      margin-bottom: 0rem;
    }
  }

  .password-control-inner {
    &.p-field {
      margin-bottom: 0.5rem;
      & > label {
        margin-bottom: 0rem;
      }
    }
  }
}
