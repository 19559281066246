@import "../../style/utils/index.scss";
.MainSideModal {
  .p-dialog {
    min-width: 40rem;
    max-width: calc(100vw - 2rem);
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    max-height: unset;
    margin: 0;
    display: flex;
    flex-direction: column;
    @include sm-screen {
      min-width: calc(100vw - 2rem);
      max-width: calc(100vw - 2rem);
    }
    .p-dialog-content {
      flex-grow: 1;
      min-height: calc(100vh - 10rem);
      padding-bottom: 1rem;
    }
    .p-dialog-footer {
      padding: 0;
    }
    .p-dialog-header .p-dialog-title {
      font-size: 2rem;
    }

    .modal-content {
      font-size: 1.5rem;
      font-weight: 500;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    .modal-content-header {
      font-size: 2rem;
    }
  }
  .main-side-modal-footer {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
