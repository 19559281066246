@import "../../../style/utils";
.p-inputgroup {
  position: relative;
}
.bc-drc-range-panel {
  // &.view-picker{
  //   margin-left: -25rem;
  // }
  .p-datepicker table td > span.p-highlight {
    font-weight: 500;
  }
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
  .p-overlaypanel-content {
    padding: 0 !important;
  }
  .p-datepicker table td {
    padding: 0.25rem !important;
  }
  .p-datepicker table td > span {
    width: 2rem !important;
    height: 2rem !important;
  }
  &.view-picker {
    .panel-body {
      .quick-action {
        @include sm-screen {
          display: none;
        }
      }
    }
  }
  .panel-body {
    display: flex;

    .quick-action {
      min-width: 10rem;
      text-align: left;

      .p-menu {
        height: 100%;
        border-radius: 0;
        border-top: 0;
        border-bottom: 0;
        border-left: 0;
      }
      .p-selectbutton {
        display: flex;
        flex-direction: column;
        .p-button {
          border-radius: 0 !important;
          text-align: left;
        }
      }
    }
    .cal-holder {
      margin-left: 1rem;
      display: flex;
      @include sm-screen {
        flex-direction: column;
        margin-left: 0;
      }
      .p-datepicker-inline {
        border: none;
      }
      &.is-disable-first-next-second-prev {
        .first-cal {
          .p-datepicker-next {
            display: none;
          }
        }
        .second-cal {
          .p-datepicker-prev {
            display: none;
          }
        }
      }
    }
  }
  .panel-footer {
    display: flex;
    padding: 0rem;
    align-items: center;
    width: 100%;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    border-radius: 0;
    .back-quick-select {
      display: none;
      @include sm-screen {
        display: block;
        flex-grow: 1;
        text-align: left;
        padding-left: 0;
        .pi {
          position: relative;
          top: 1px;
        }
      }
    }
    .display-date {
      flex-grow: 1;
      font-size: 1.25rem;
      padding-left: 2rem;
      height: 2rem;
      @include sm-screen {
        display: none;
      }
    }
  }
}
.date-range-control-inner {
  button.p-button.p-component.p-datepicker-trigger {
    background: transparent;
    border: transparent;
    color: var(--surface-f);
    position: absolute;
    right: 0;
    &:hover {
      color: var(--primary-color);
    }
    @include sm-screen {
      display: none;
    }
  }
}
