.log-files {
  .action-column {
    > .bt-row {
      // width: 100%;
      display: flex !important;
      justify-content: flex-end !important;
    }
    > .p-column-title {
      display: flex !important;
      justify-content: flex-end !important;
    }
  }
}
