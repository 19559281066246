@import "../../../../style/utils/index.scss";

.main-sidebar {
  background: var(--surface-a);
  position: fixed;
  left: 0;
  top: 0;
  width: 15rem;
  padding-top: 1rem;
  height: calc(100vh - 82px);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);

  &.open {
    @include sm-screen {
      width: 100vw;
      position: static;
    }

    .tree-sidebar {
      padding: 0;
      border: 0;

      .p-tree-toggler {
        order: 99;
      }

      .p-treenode-label {
        flex-grow: 1;
      }
    }

    .menu-active {
      background: transparent !important;

      > .p-treenode-label,
      > .p-treenode-icon,
      > .p-tree-toggler {
        font-weight: 500;
      }

      &.menu-leaf {
        background: var(--surface-d) !important;

        > .p-treenode-label,
        > .p-treenode-icon,
        > .p-tree-toggler {
          color: var(--primary-color) !important;
        }
      }
    }
  }

  &.close {
    @include sm-screen {
      display: none;
    }

    width: 3rem;

    .p-tieredmenu {
      width: 3rem;
      border: none;
    }

    .p-tieredmenu .p-menuitem-link .p-menuitem-text {
      display: none;
    }

    .p-tieredmenu .p-submenu-list .p-menuitem-link .p-menuitem-text {
      display: block;
    }

    .p-tieredmenu .menu-main .p-menuitem-link .pi-angle-right {
      opacity: 0 !important;
    }

    .p-tieredmenu .menu-sub .p-menuitem-link .pi-angle-right {
      opacity: 1 !important;
    }

    .p-tieredmenu .p-menuitem-active > .p-submenu-list {
      min-width: 15rem;
    }

    .menu-active > .p-menuitem-link {
      background: var(--surface-d) !important;
      color: var(--primary-color) !important;

      .p-menuitem-icon,
      .p-menuitem-text,
      .p-submenu-icon {
        font-weight: 500;
        color: var(--primary-color) !important;
      }

      &.menu-leaf {
        background: var(--surface-d) !important;

        > .p-treenode-label,
        > .p-treenode-icon,
        > .p-tree-toggler {
          color: var(--primary-color) !important;
        }
      }
    }
  }
}

/* Sidebar Left Main Layout */

.sidebar-left {
  margin: 0px;
  padding: 0px;
  width: auto;
  top: 82px;
  background-color: var(--sidebar-primary-color);

  @include md-screen {
    top: 5.5rem;
  }
}

// .sidebar-left-submenu-mobile-expand {
//   @include sm-screen {
//     width: calc(85.3px + 181px);
//   }
// }

.p-sidebar-mask.p-component-overlay {
  top: 82px;
}

/* Sidebar Menus Container */

#sidebar-menus-container {
  margin: 15px 0px 15px 0px;
  padding: 0px;
  list-style-type: none;

  @include md-screen {
    cursor: pointer;
  }

  @media screen and (min-width: 769px) {
    margin: 35px 0px 15px 0px;
  }
}

/* Sidebar Menu Item List Container */

.sidebar-menu-item-list-container {
  margin: 5px 0px;
}

/* Selected Sidebar Menu Item List Container  */

.selected-sidebar-menu-item-list-container {
  background-color: var(--surface-a);
}

/* Sidebar Menu Item Container */

.sidebar-menu-item-container {
  display: block;
  margin: 0px 5px 0px 0px;
  padding: 10px;
  width: auto;
  background-color: var(--sidebar-primary-color);
  align-items: center;
  justify-content: center;
  text-align: left;

  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Selected Sidebar Menu Item Container */

.selected-sidebar-menu-item-container {
  background-color: var(--surface-a);
  border-left: 5px solid var(--sidebar-active-menu);
}

.sidebar-menu-item-icon {
  color: var(--surface-a);
  font-size: 28px;
}

/* Selected Sidebar Menu Item Icon */

.selected-sidebar-menu-item-icon {
  color: var(--sidebar-active-menu);
  /* https://codepen.io/sosuke/pen/Pjoqqp */
  /* As noted in the CodePen, if your SVG isn't black (ours was white), adding brightness(0) saturate(100%) to the beginning of the list of filters will first turn it 100% black, which enables the other filters to change it to the correct color. */
  filter: brightness(0) saturate(100%) invert(14%) sepia(61%) saturate(6173%)
    hue-rotate(350deg) brightness(81%) contrast(90%);
}

.sidebar-menu-item-text {
  font-family: var(--font-family-sidebar);
  font-size: 15px;
  color: var(--primary-color-text);
}

/* Selected Sidebar Menu Item Text */

.selected-sidebar-menu-item-text {
  color: var(--sidebar-active-menu);
}

/* Sidebar Menu Scroll Panel Wrapper */

.sidebar-left-scroll-panel > .p-scrollpanel-wrapper {
  border-right: 0px solid transparent;
  height: calc(100vh - 74px);
  width: 300px;

  @include md-screen {
    height: calc(100vh - 70px);
    width: 295px;
  }
  @include sm-screen {
    width: 224px;
  }
}

/* Sidebar Menu Scroll Panel Scroll Bar */

.sidebar-left-scroll-panel > .p-scrollpanel-bar {
  margin: 0px;
  padding: 0px;
  background-color: transparent;
}

/* Sidebar Menu Scroll Panel Content */

.sidebar-left-scroll-panel > .p-scrollpanel-wrapper > .p-scrollpanel-content {
  height: calc(100% + 25px);
  padding: 0px 0px 24px 0px;
}

/* My Docs Submenus Container */

.menu-item-my-docs-submenus-container {
  display: block;
  position: absolute;
  visibility: collapse;
  top: -0.35px;
  left: 21rem;
  @include sm-screen {
    left: 16rem;
  }
}

/* Show My Docs Submenus Container */

.selected-menu-item-my-docs-submenus-container {
  visibility: visible;
}

/* My Docs Submenus Content */

.menu-item-my-docs-submenus-content {
  display: block;
  position: fixed;
  background-color: var(--surface-a);
  min-width: 181px;
  z-index: 1;
  height: calc(100vh - 48px);
}

.menu-item-my-docs-submenus-heading-text {
  margin: 20px 0px 45px 0px;
  padding: 0px 0px 0px 10px;
  font-size: 18px;
  font-family: var(--font-family-sidebar);
  color: var(--secondary-color-text);
  display: flex;
  align-items: center;

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  p {
    top: 84px;
    left: 118px;
    // width: 129px;
    // height: 23px;
    color: var(--surface-j);
    text-align: left;
    font: normal normal normal 18px/23px IBM Plex Sans;
    font-family: var(--font-family-sidebar);
    letter-spacing: 0.03px;
    opacity: 1;
  }

  .p-button.p-button-icon-only {
    width: 1rem;
    margin-right: 5px;
  }
}

/* My Docs Container */

.my-docs-container {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.my-docs-list-container {
  margin: 5px 0px;

  @include md-screen {
    cursor: pointer;
  }
}

.my-docs-list-container:hover {
  @include md-screen {
    cursor: pointer;
  }

  .my-docs-item-container {
    background-color: var(--sidebar-active-submenu);

    .my-docs-item-text {
      color: var(--sidebar-active-menu);
    }
  }
}

.my-docs-item-container {
  display: block;
  margin: 0px;
  padding: 17px 0px 17px 25px;
}

/* Selected My Docs Item Container */

.selected-my-docs-item-container {
  padding-left: 20px;
  background-color: var(--sidebar-active-submenu);
  border-left: 5px solid var(--sidebar-active-menu);
}

.my-docs-item-text {
  font-family: var(--font-family-sidebar);
  color: var(--secondary-color-text);
  font-size: 12px;
}

/* Selected My Docs Item Text */

.selected-my-docs-item-text {
  color: var(--sidebar-active-menu);
}

/* Sidebar SubMenu Scroll Panel Wrapper */
.sidebar-submenu-scroll-panel > .p-scrollpanel-wrapper {
  border-right: 0px solid transparent;
  height: calc(100vh - 206px);

  @include md-screen {
    height: calc(100vh - 202px);
  }
}

/* Sidebar SubMenu Scroll Panel Scroll Bar */
.sidebar-submenu-scroll-panel > .p-scrollpanel-bar {
  background-color: transparent;
}

/* Sidebar SubMenu Scroll Panel Content */
.sidebar-submenu-scroll-panel
  > .p-scrollpanel-wrapper
  > .p-scrollpanel-content {
  height: calc(100% + 25px);
  padding: 0px 0px 24px 0px;
}
