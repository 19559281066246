.common-header {
  padding: 0.5rem;
  background: var(--surface-a);
  color: var(--surface-f);
  border-radius: 0px;
  position: relative;
  width: 100% !important;

  .common-entity-logo {
    top: 6px;
    left: 30px;
    width: 96px;
    height: 68px;
    margin-left: 30px;
  }
}

.common-content {
  margin: 34px;
}
