@import "../../style/utils";

.bt-table-custom {
  .hide-selection {
    .selection-column {
      opacity: 0.2 !important;
      pointer-events: none;
      .p-checkbox .p-checkbox-box.p-highlight {
        border-color: unset;
        background: transparent;
      }
    }
  }
  .hide-action {
    .action-column {
      opacity: 0.2 !important;
      pointer-events: none;
    }
  }
  &.bt-table-processing {
    pointer-events: none;
  }
  .p-datatable-scrollable-body {
    background-color: var(--surface-a);
  }
  table.p-datatable-scrollable-header-table {
    background: var(--surface-d);
  }
  .p-datatable-frozen-view {
    width: 100% !important;
  }
  // .p-datatable-unfrozen-view {
  //   margin-left: -5rem;
  // }
  .p-datatable .p-datatable-thead > tr > th {
    color: var(--surface-f);
    background: var(--surface-g);
    /*aw*/
    .p-column-title {
      word-break: break-word;
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .p-datatable .p-sortable-column:hover {
    color: var(--surface-f) !important;
    background: var(--surface-d) !important;
  }
  .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: var(--surface-f) !important;
  }
  .p-sortable-column-icon.pi-sort-amount-up-alt:before {
    content: "\e91c" !important;
    color: var(--surface-f) !important;
  }
  .p-sortable-column-icon.pi-sort-amount-down:before {
    content: "\e919" !important;
    color: var(--surface-f) !important;
  }

  // .p-filter-column {
  //   background: var(--surface-a) !important;
  // }
}
.open-sidebar {
  .datatable-grid {
    margin-left: -1rem;
    tbody.p-datatable-tbody {
      min-width: calc(100vw - 19rem);
      @include sm-screen {
        min-width: calc(100vw - 2rem);
        margin-left: 10px;
      }
    }
  }
}
.datatable-grid {
  .action-column {
    display: none;
  }
  margin-left: -1rem;
  .p-datatable-scrollable-body {
    overflow: hidden;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    border: none;
  }
  .p-datatable .p-paginator-bottom {
    background: transparent;
    border: transparent;
    justify-content: flex-end;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: transparent;
    border-color: transparent;
    border-bottom: 2px solid var(--primary-color);
  }
  .p-paginator-prev {
    &::before {
      display: none !important;
    }
  }
  .p-paginator-next {
    &::before {
      display: none !important;
    }
  }
  .p-datatable-scrollable-wrapper {
    background: unset !important;
  }
  .p-datatable-emptymessage {
    margin-top: 1.5rem;
    width: 100% !important;
    height: unset !important;
    td {
      display: block !important;
      text-align: center !important;
      border: none !important;
    }
  }
  tbody.p-datatable-tbody {
    display: flex;
    flex-wrap: wrap;
    min-width: calc(100vw - 7rem);
  }

  .p-datatable-scrollable-wrapper {
    background: unset;
  }
  .p-datatable-scrollable-body-table {
    width: unset;
    background: unset;
  }
  .p-datatable-scrollable-header {
    display: none;
  }
  .p-datatable-tbody > tr {
    display: block !important;
    width: 33.33%;
    height: 35rem;
    overflow: hidden;
    padding: 1rem;
    position: relative;
  }
  .p-datatable-tbody > tr > td {
    display: none;
    &.p-selection-column {
      z-index: 99999;
      position: absolute;
      top: 1rem;
      display: block;
      right: 1rem;
      border: 0;
    }
    &.bt-grid-column {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
.bt-table {
  .p-datatable-scrollable-wrapper {
    background: var(--primary-color-text);
  }
  .p-paginator {
    display: flex;
    margin: 0;
    align-items: center;
    // margin-top: -7px;
    @include sm-screen {
      justify-content: flex-end;
    }
    border-top: 1px solid var(--surface-d);
    padding: 0;
    .p-paginator-title {
      margin-right: 1rem;
      padding-left: 1rem;
      @include sm-screen {
        display: none;
      }
    }
    .p-dropdown {
      border: transparent;
      margin-right: 0.5rem;

      @include sm-screen {
        display: none;
      }
    }
    .p-paginator-current {
      flex-grow: 1;
      justify-content: end;
      margin: 0;
      position: relative;
      height: 3.5rem;
      &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background: var(--surface-d);
      }
      @include sm-screen {
        display: none;
        &::before {
          display: none;
        }
      }
    }
    .p-paginator-pages {
      margin-right: 1rem;

      @include sm-screen {
        order: 1;
        margin-right: 0;
      }
      .p-paginator-page {
        background: transparent !important;
        border-color: transparent !important;
        &.p-highlight {
          background: transparent !important;
          border-color: transparent !important;
          border-bottom: 2px solid var(--primary-color) !important;
        }
      }
    }
    .p-paginator-prev {
      position: relative;
      margin: 0;
      height: 3.5rem;
      width: 3.5rem;
      &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background: var(--surface-d);
      }

      @include sm-screen {
        order: 0;
        &::before {
          display: none;
        }
      }
    }
    .p-paginator-next {
      position: relative;
      margin: 0;
      height: 3.5rem;
      width: 3.5rem;
      &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background: var(--surface-d);
      }

      @include sm-screen {
        order: 2;
        &::before {
          display: none;
        }
      }
    }
  }
  .bt-table-loading {
    width: 100%;
    text-align: center;
  }
  // padding: 1rem;
  .p-dataview-content {
    background: transparent;
  }
  .p-sortable-column {
    position: relative;
    &.p-highlight,
    &:hover {
      .p-sortable-column-icon {
        opacity: 1;
      }
    }
  }
  /*aw*/
  th:not(.table-expandable-header) > .p-sortable-column-icon {
    opacity: 0;
    position: absolute;
    right: 1rem;
    top: calc(50% - 0.5rem);
  }
  .p-datatable-scrollable-header-box {
    margin-right: 0 !important;
  }
  .bt-cell-value {
    /*word-break: break-all;*/
    flex-grow: 1;
    @include sm-screen {
      .p-skeleton {
        min-width: 100px;
        top: 3px;
      }
    }
  }
  .p-datatable-frozen-view {
    .p-datatable-emptymessage {
      td {
        color: transparent;
      }
    }
  }
  .p-datatable-unfrozen-view {
    // &.have-scroll-left {
    &::before {
      content: "";
      position: absolute;
      left: -2px;
      top: 0;
      height: 100%;
      width: 1px;
      // background: var(--text-color-secondary);
      z-index: 999;
      box-shadow: 2px 0 3px 0px var(--text-color-secondary);
    }
    // }
    // &.have-scroll-right {
    &::after {
      content: "";
      position: absolute;
      right: -2px;
      top: 0;
      height: 100%;
      width: 1px;
      // background: var(--text-color-secondary);
      z-index: 999;
      box-shadow: -2px 0 3px 0px var(--text-color-secondary);
    }
    // }
  }
  .bt-header {
    display: flex;
    flex-direction: column;
    .bt-action-head {
      margin-bottom: 0.5rem;
      .p-button {
        margin-left: 0.5rem;
      }
    }
    .bt-header-toolbar {
      display: flex;
      .bt-title-group {
        flex-grow: 1;
      }
    }
    .bt-title {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .bt-desc {
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.4rem;
    }
    .bt-toolbar {
      margin-top: 1rem;
      min-height: 35px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      .left-panel {
        flex-grow: 1;
      }
      .left-panel {
        flex-grow: 1;
      }
      .bt-dump {
        flex-grow: 1;
      }
      .bt-action {
        .bt-filterbtn {
          &.p-highlight {
            background: var(--surface-f) !important;
            border-color: var(--surface-f) !important;
            * {
              color: var(--primary-color-text) !important;
            }
          }
        }
        .bt-visiblechoice,
        .bt-filterselect {
          width: 100%;
          height: 100%;
          min-height: 35px;
          margin-right: 0.5rem;
          align-content: center;
          display: inline-grid;
          text-align: center;
          border: none;
          background: transparent;

          .p-multiselect-trigger {
            position: relative;
            left: 5px;
            height: 2rem;
            .p-multiselect-trigger-icon {
              &::before {
                content: "\e991";
              }
            }
          }
          .p-multiselect-label-container {
            display: none;
          }
        }
        .bt-filterselect {
          .p-multiselect-trigger {
            .p-multiselect-trigger-icon {
              &::before {
                content: "\e94c";
              }
            }
          }
        }

        &.bt-action-search {
          flex-grow: 1;
          .p-input-icon-left {
            width: 100%;
            display: flex;
            input {
              border: none;
            }
          }
        }
      }
      &.bt-toolbar-batch {
        background: var(--primary-color);
        .bt-selected-label {
          color: var(--primary-color-text);
          padding-left: 1rem;
        }
      }
    }
  }
  .bt-grid-content {
    // min-height: 24rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--surface-a);
    .bt-grid-body {
      flex-grow: 1;
      margin-top: 1rem;
    }
    .bt-grid-footer {
      width: 100%;
      display: flex;
      .p-button {
        width: 100%;
      }
    }
  }

  ////
  .p-slidemenu-rootlist {
    width: 100% !important;
  }
  // Fixed css
  th,
  td {
    vertical-align: middle;
  }
  .p-filter-column {
    padding: 0.5rem 0.25rem !important;
    .p-checkbox {
      position: relative;
      left: 0.75rem;
    }
    .p-inputtext {
      width: 100%;
      // font-size: 0.8rem;
      // padding: 0.5rem;
      // height: 2rem;
    }
  }

  .p-filter-column:first-child {
    padding-left: 0.5rem !important;
  }

  .p-filter-column:last-child {
    padding-right: 0.5rem !important;
  }
  .show-in-sm {
    display: none;
  }
  .advanced-form {
    margin-top: 2rem;
  }
  &.datatable-responsive {
    .advanced-form {
      margin-top: 2rem;
      &.show-in-sm {
        @include sm-screen {
          display: block;
        }
      }
    }
  }

  @include sm-screen {
    .datatable-grid .p-datatable-tbody > tr {
      width: 100%;

      height: 25rem;
    }
    td {
      .p-button-icon-only {
        padding: 0;
      }
    }
    .bt-table .p-paginator .p-paginator-current {
      display: none;
    }
    .p-datatable .p-datatable-tbody > tr > td {
      padding: 0.5rem;
    }
    .p-component {
      font-size: 12px;
    }
    .p-datatable-scrollable-view {
      width: 100% !important;
      margin: 0 !important;
    }
  }
}
.bt-visiblechoice-panel,
.bt-filterselect-panel {
  .p-multiselect-filter {
    border: none;
    background: none;
    font-weight: bold;
    pointer-events: none;
    padding: 0;
  }
  .p-multiselect-filter-icon {
    display: none;
  }
  .bt-frozen {
    pointer-events: none;
    background: transparent !important;
    .p-checkbox {
      visibility: hidden;
    }
  }

  .p-multiselect-item {
    &.p-highlight {
      background: transparent !important;
    }
  }
}
tr > td .p-column-title {
  display: none;
}

/*aw*/
.datatable-responsive
  > .mobile-view
  > .p-datatable-scrollable-wrapper
  > .p-datatable-scrollable-view
  > .p-datatable-scrollable-header
  > .p-datatable-scrollable-header-box
  > table.p-datatable-scrollable-header-table {
  display: none;
}

.datatable-responsive
  .p-datatable-responsive
  .p-treetable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}
.datatable-responsive
  .p-datatable-responsive
  .p-datatable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}
@include sm-screen {
  /*aw*/
  .datatable-responsive:not(.expander-datatable) .p-datatable-scrollable-body {
    overflow: hidden;
  }
  .datatable-responsive table.p-datatable-scrollable-body-table {
    table-layout: auto;
    .p-datatable-tbody {
      overflow: hidden;
    }
  }
  /*aw*/
  .datatable-responsive
    table:not(.mobile-expander-header-style).p-datatable-scrollable-body-table
    .p-selection-column {
    display: none;
  }
  /*aw*/
  .datatable-responsive
    .p-datatable.p-datatable-responsive:not(.mobile-expander)
    .p-datatable-thead
    > tr
    > th,
  .datatable-responsive
    .p-datatable.p-datatable-responsive
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }

  //aw - header styling
  .datatable-responsive
    table.mobile-expander-header-style
    > .p-datatable-thead
    > tr {
    > .bt-frozen-right.action-column.table-expandable-header {
      padding: 0;
      width: 3rem;
    }
    > th {
      padding: 0.5rem 1.5rem 0.5rem 0.5rem;
      > .p-column-title {
        display: inline;
      }
      > .p-sortable-column-icon.pi.pi-fw {
        display: inline;
        position: absolute;
        right: 0;
        top: calc(50% - 0.4rem);
        font-size: 12px;
        margin-right: 0.5rem;
      }
    }
  }

  /* Payslip, Tax Docs, Compensation and Hr Letters Table - Mobile Expander Subheader and Subfooter Style for Mobile */
  #payslip-table.mobile-expander,
  #tax-docs-table.mobile-expander {
    table.mobile-expander-header-style {
      tbody.p-datatable-tbody {
        // Subheader style for mobile
        tr.p-rowgroup-header {
          background-color: var(--surface-e);
          td {
            > span.p-rowgroup-header-name {
              div {
                font-weight: bold;
                color: var(--surface-a);
              }
            }
          }
        }
        // Subfooter style for mobile
        tr.p-rowgroup-footer {
          display: none;
        }
      }
    }
  }

  // aw
  .datatable-responsive
    .p-datatable.mobile-expander
    .p-datatable-thead
    > tr
    > th {
    .datatable-responsive
      .p-datatable.p-datatable-responsive
      .p-datatable-thead
      > tr
      > th {
      display: none !important;
    }
  }

  .datatable-responsive
    .p-datatable.p-datatable-responsive
    table:not(.mobile-expander-header-style) /*aw*/
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    width: calc(100vw - 3rem) !important;
    float: left;
    clear: left;
    border: 0 none;
    .bt-cell {
      display: flex;
    }
  }

  .datatable-responsive
    .p-datatable.p-datatable-responsive
    .p-datatable-tbody
    > tr
    > td:not(.table-expandable-header) /*aw*/
    .p-column-title {
    padding: 0.4em;
    display: inline-block;
    margin: -0.4em 0.25em -0.4em -0.4rem;
    font-weight: bold;
  }

  .datatable-responsive
    .p-datatable.p-datatable-responsive
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-bottom: 1px solid var(--overlay-b);
  }
}

.status {
  .dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
    background: var(--text-color-secondary);
  }
  &.status-inactive,
  &.status-te {
    .dot {
      background: red;
    }
  }
  &.status-active,
  &.status-ac {
    .dot {
      background: green;
    }
  }
  &.status-ao {
    .dot {
      background: #0683ff;
    }
  }
  &.status-up {
    .dot {
      background: #9c27b0;
    }
  }
  &.status-closed {
    .dot {
      background: var(--text-color-secondary);
    }
  }
}
.bt-content-dummy {
  color: transparent;
}

/* expander */
.table-header-container {
  justify-content: left;
  // display: flex;
  // flex-wrap: nowrap;
  .button-enabled:disabled {
    width: 122px;
    height: 40px;
    background-color: #787878;
    border: 1px solid #787878;
    color: #ffffff;
    font-size: 14px;
    margin: 0.3rem 0.3rem 0 0.3rem;
    > .pi {
      margin: 0;
      font-size: 10px;
    }
  }
  .button-enabled {
    width: 122px;
    height: 40px;
    background-color: #ba1b22;
    border: 1px solid #ba1b22;
    color: #ffffff;
    font-size: 14px;
    margin: 0.3rem 0.3rem 0 0.3rem;
    > .pi {
      margin: 0;
      font-size: 10px;
    }
  }
}

/* Notification Table - On Row Selected - Active State */
#notification-table .p-datatable-tbody > tr.p-highlight {
  background-color: var(--surface-h);
}

#notification-table .p-datatable-tbody > tr.p-highlight.p-selectable-row {
  transition: box-shadow 0.2s;
  outline-color: var(--surface-h);
}

#notification-table .p-datatable-tbody > .p-highlight.p-selectable-row > td {
  font-weight: bold;
  color: var(--table-row-active);
}

#notification-table .p-datatable-tbody {
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

.hris-bt-expander-column {
  width: 3em;
  padding: 0;
}

/* Payslip, Tax Docs, Compensation and Hr Letters Table - Subheader and Subfooter Style for Desktop */
#payslip-table.web-view,
#tax-docs-table.web-view {
  // Subheader display none in unfrozen column for desktop
  div.p-datatable-scrollable-view.p-datatable-unfrozen-view {
    table.p-datatable-scrollable-body-table {
      tbody.p-datatable-tbody {
        tr.p-rowgroup-header {
          td {
            .p-rowgroup-header-name {
              visibility: hidden;
            }
          }
        }
      }
    }
  }
  table.p-datatable-scrollable-body-table {
    tbody.p-datatable-tbody {
      // Subheader style for desktop
      tr.p-rowgroup-header {
        @include md-screen {
          cursor: default;
        }
        background-color: var(--surface-e);
        td {
          > span.p-rowgroup-header-name {
            div {
              font-weight: bold;
              color: var(--surface-a);
              width: 19rem; // 20rem - 1rem from padding right to center align
            }
          }
        }
      }
      // Subfooter style for desktop
      tr.p-rowgroup-footer {
        display: none;
      }
    }
  }
}

// Align base table filter input control
#payslip-table.web-view,
#tax-docs-table.web-view,
#compensation-docs-table.web-view,
#hr-letters-table.web-view,
#notification-table {
  .input-control-inner {
    &.p-field {
      margin-bottom: 0rem;
    }
  }
}
