.main-breadcrumb > .p-breadcrumb ul li.p-breadcrumb-chevron:before {
  color: var(--text-color-secondary);
}

.main-breadcrumb > .p-breadcrumb ul li:last-child.p-breadcrumb-chevron {
  display: none;
}

// .main-breadcrumb > .p-breadcrumb ul li:first-child.p-breadcrumb-chevron {
//   display: none;
// }

// disable breadcrumb link
.main-breadcrumb > .p-breadcrumb ul li a.p-menuitem-link {
  cursor: default;
  &:hover {
    background: inherit;
  }
  &:focus {
    box-shadow: none;
  }
}

.main-breadcrumb > .p-breadcrumb ul li .p-menuitem-text {
  color: var(--text-color-secondary);
  word-break: break-word;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.main-breadcrumb > .p-breadcrumb ul li:last-child .p-menuitem-text {
  color: var(--primary-color);
}
