.shortcut-container {
  margin-top: 1.5rem;
  margin-bottom: 29rem;

  .shortcut {
    div {
      background-color: var(--surface-a);
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
      column-gap: normal;
    }

    a {
      text-decoration: none;
      color: var(--primary-color);
      width: 100%;

      span {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-size: 5vh;
        // width: 64px;
        height: 64px;
      }

      p {
        color: var(--surface-f);
        display: flex;
        flex-wrap: wrap;
        text-align: center;
      }
    }
  }
}
