.checkbox-control {
  .control-hint-bottom {
    display: inline-block;
    margin-top: 0.25rem;
  }

  .control-hint-right {
    display: flex;
    align-items: center;
    margin: 0 0 0 1rem;
  }

  .p-checkbox-label {
    margin-left: 0.5rem;
  }
}
