@import "./../../../../../../style/utils/index.scss";

.main-language {
  align-items: center;
  .p-button-icon-right {
    margin-right: 1rem;
  }
  .p-tieredmenu {
    border: none;
    width: 100%;
  }
  .main-language-button {
    background: white;
    color: black !important;
    // max-width: 26.62px;
    // max-height: 26.62px;
    // height: 100%;
    padding: 0.25rem 0.25rem;
    width: 100%;
    // margin-top: 17px;
  }
  .main-language-login-button {
    background: white;
    color: black !important;
    width: 100%;
  }
  .language-icon-size {
    font-size: 24px;
  }
  .language-icon-size-login {
    font-size: 24px;
    margin-right: 0.5rem;
  }
}

.main-language-login-panel {
  @include sm-screen {
    top: 55px !important;
  }
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
}

.main-language-panel {
  @include sm-screen {
    top: 69px !important;
  }
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
}

.c-setting-tree {
  padding: 0;
  border: 0;
  background: transparent;
  flex-direction: row;

  .p-tree-container .p-treenode {
    padding: 0rem;

    .p-treenode-content {
      background-color: var(--surface-a);
      font-weight: 500;
      margin-left: 4px;
      position: relative;
      flex-direction: row;
      &::before {
        content: "";
        background-color: var(--surface-c);
        position: absolute;
        width: 0.5rem;
        top: 0;
        left: -0.5rem;
        height: 100%;
      }
      &:hover,
      &.p-highlight {
        &::before {
          background-color: var(--primary-color);
        }
      }
      .p-treenode-label {
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .p-tree-toggler {
        width: 0rem !important;
      }
    }
  }
}
