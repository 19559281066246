.clients {
  .p-card {
    margin: 1rem;
  }
}

.data-table-expander-container {
  .p-row-expanded > td > .p-card {
    box-shadow: none !important;

    > .p-card-body {
      padding: 0;

      > .p-card-content {
        padding: 0;

        > div {
          min-height: auto !important;

          > .bt-header {
            display: none;
          }

          .datatable-responsive
            .p-datatable.p-datatable-responsive
            table:not(.mobile-expander-header-style)
            .p-datatable-tbody
            > tr
            > td {
            text-align: left;
            width: auto !important;
            float: left;
            clear: left;
            border: 0 none;
          }

          .datatable-responsive
            .p-datatable.p-datatable-responsive
            table:not(.mobile-expander-header-style)
            .p-datatable-tbody
            > tr
            > td:last-child {
            text-align: left;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;
            border-bottom: 1px solid var(--surface-d);
            text-align: right;
          }

          > .datatable-responsive {
            > div {
              display: flex;
              flex-wrap: wrap;

              .bt-row {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;

                > .p-column-title {
                  // font-weight: normal;
                  padding: 0;
                  margin: 0;
                }

                > .bt-cell-value {
                  flex-grow: 0;
                }
              }

              > .p-paginator-bottom {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.action {
  display: flex;
  justify-content: space-around;
  width: 60%;
}
