@import "primeflex/src/_variables.scss";
@mixin xs-screen {
  @media screen and (max-width: $sm) {
    @content;
  }
}
@mixin sm-screen {
  @media screen and (max-width: $sm) {
    @content;
  }
}

@mixin md-screen {
  @media screen and (min-width: $md) {
    @content;
  }
}

@mixin lg-screen {
  @media screen and (min-width: $lg) {
    @content;
  }
}

@mixin xl-screen {
  @media screen and (min-width: $xl) {
    @content;
  }
}
