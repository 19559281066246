.ep-login-alert {
  // .p-message
  .p-message.p-message-success {
    background: transparent;
    width: max-content;
    border: 1px solid var(--message-success-primary);
    width: 100%;
    .p-message-wrapper {
      width: 100%;
      padding: 0rem;
      align-items: stretch;
      .p-message-icon {
        background: var(--message-success-icon-primary);
        margin-right: 0rem;
        text-align: center;
        padding-top: 1rem;
        width: 10%;
        font-size: 13px;
      }
      .p-message-summary {
        display: none;
      }
      .p-message-detail {
        padding: 1rem;
        width: 80%;
        align-self: center;
        margin: auto;
      }
      .p-message-close {
        padding: 1rem;
        height: 100%;
        .p-message-close-icon {
          font-size: 8px;
        }
      }
    }
  }

  .p-message.p-message-warn {
    background: transparent;
    width: max-content;
    border: 1px solid var(--message-warn-primary);
    width: 100%;
    .p-message-wrapper {
      width: 100%;
      padding: 0rem;
      align-items: stretch;
      .p-message-icon {
        background: var(--message-warn-icon-primary);
        margin-right: 0rem;
        text-align: center;
        padding-top: 1rem;
        width: 10%;
        font-size: 13px;
      }
      .p-message-summary {
        display: none;
      }
      .p-message-detail {
        padding: 1rem;
        width: 80%;
        align-self: center;
        margin: auto;
      }
      .p-message-close {
        padding: 1rem;
        height: 100%;
        .p-message-close-icon {
          font-size: 8px;
        }
      }
    }
  }

  .p-message.p-message-error {
    background: transparent;
    width: max-content;
    border: 1px solid var(--message-error-primary);
    width: 100%;
    .p-message-wrapper {
      width: 100%;
      padding: 0rem;
      align-items: stretch;
      .p-message-icon {
        background: var(--message-error-icon-primary);
        margin-right: 0rem;
        text-align: center;
        padding-top: 1rem;
        width: 10%;
        font-size: 13px;
      }
      .p-message-summary {
        display: none;
      }
      .p-message-detail {
        padding: 1rem;
        width: 80%;
        align-self: center;
        margin: auto;
      }
      .p-message-close {
        padding: 1rem;
        height: 100%;
        .p-message-close-icon {
          font-size: 8px;
        }
      }
    }
  }
}

.hris-custom-message {
  font-weight: bold;
  margin: 0rem;
  font-size: 16px;
}
