.ep-login-footer {
  bottom: 1rem;
  right: 0;
  left: 0;
  position: fixed;
  z-index: -1;

  .copy-rights {
    margin: 0;
    font-size: 14px;
    text-align: center;
  }
}
