.menu-group {
  font-weight: bold !important;
  opacity: 1 !important;
  position: relative !important;
  left: -0.25rem !important;
}
.radio-group {
  display: flex;
  &.vertical {
    flex-direction: column;
  }
  align-items: flex-start;
  .p-field-radiobutton {
    margin-right: 1.5rem;
    label {
      text-overflow: unset;
      overflow: unset;
    }
  }
}
