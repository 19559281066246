.welcome-bg {
  margin: 0;
  padding: 0 10px;
  // position: relative;
  background-color: var(--surface-h);
  border-radius: 8px;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 155px;
  justify-content: space-between;

  div {
    span {
      height: 100%;
      width: 100%;
      .welcome-img {
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        flex-shrink: 0;
      }
    }
  }
  .welcome-info {
    // position: relative;
    text-align: left;
    color: var(--surface-f);
    display: table;
    span {
      display: table-row;
      h1 {
        -ms-word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: var(--secondary-color-text);
        opacity: 1;
        font-size: 22px;
      }
      p {
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: var(--secondary-color-text);
        opacity: 1;
        text-align: left;
      }
    }
  }
}
