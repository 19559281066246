.footer {
  background-color: var(--surface-d);
  height: 60px;
  // margin: -1.8rem -1.6rem;
  padding: 0rem 1.6rem;
  width: 100%;
  position: fixed;
  bottom: 1px;
  right: 7px;

  .secondary {
    color: var(--secondary-color-text);
    border-color: var(--secondary-color-text);
  }

  .p-button {
    min-width: 70px;
    display: inline;
  }

  .next-button {
    margin-left: 1rem;
  }
}

.support-file-upload {
  margin: 1rem 0 0 0;
}

.file-upload__header {
  background: var(--surface-d) !important;
}

.file-uploader-container {
  .support-file-upload {
    .p-fileupload-content {
      .p-fileupload-files {
        .p-fileupload-row {
          display: flex;
          flex-direction: row;

          .file-uploader-items {
            .support-document-item-template {
              -ms-word-break: break-all;
              /* Instead use this non-standard one: */
              word-break: break-word;
              white-space: pre-wrap;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }

            button:nth-child(2) {
              margin: 0 1rem 0 0;
            }
          }
        }
      }
    }
  }
}

.support-document-file-upload-error {
  color: var(--message-error-primary);
}

.support-document-file-upload-message {
  color: var(--message-success-primary);
}
