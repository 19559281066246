.features {
  .expander-control {
    &-none {
      button {
        display: none;
      }
    }
  }

  .features-panel {
    div.features-menu {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .p-tag {
      &.p-tag-info {
        background-color: gray;
        color: #ffffff;
      }

      &.abort {
        background-color: yellow;
        color: #ffffff;
      }
    }

    .feature-scheduler-log-header {
      margin-top: 1rem;
      font-weight: 800;
      text-decoration: underline;
    }

    .features-message-panel {
      display: flex;
      align-items: center;
      .features-message-panel-status {
        margin-right: 1rem;
      }
      .features-message-panel-details {
        display: grid;
      }
    }

    thead.p-datatable-thead {
      th.p-sortable-disabled {
        background: none;
        font-weight: bold;
        border-bottom: 2px solid;
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .checkbox {
    display: inline;
    height: 50px;
    width: 50px;
    background: #fff;
    border: 1px #ddd solid;
    margin-right: 10px;
  }

  .checkbox--active {
    border-color: red;
    background: red;
  }

  .field-checkbox {
    margin-left: 1rem;
    line-height: 2;
    position: relative;
  }

  .bt-submit {
    margin-top: 1rem;
    min-height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 25%;
    .left-panel {
      flex-grow: 1;
    }
    .left-panel {
      flex-grow: 1;
    }
    .bt-dump {
      flex-grow: 1;
    }
    .bt-action {
      .bt-filterbtn {
        &.p-highlight {
          background: var(--surface-f) !important;
          border-color: var(--surface-f) !important;
          * {
            color: var(--primary-color-text) !important;
          }
        }
      }
      .bt-visiblechoice,
      .bt-filterselect {
        width: 100%;
        height: 100%;
        min-height: 35px;
        margin-right: 0.5rem;
        align-content: center;
        display: inline-grid;
        text-align: center;
        border: none;
        background: transparent;
      }

      &.bt-action-search {
        flex-grow: 1;
        .p-input-icon-left {
          width: 100%;
          display: flex;
          input {
            border: none;
          }
        }
      }
    }
    &.bt-submit-batch {
      background: var(--primary-color);
      .bt-selected-label {
        color: var(--primary-color-text);
        padding-left: -1rem;
      }
    }
  }

  div.p-main-header {
    > div {
      align-items: center;
      justify-content: space-between;
      display: flex !important;

      > span {
        position: relative;
        display: inline-block;
        > i {
          left: 0.75rem;
          color: #6c757d;
          position: absolute;
          top: 50%;
          margin-top: -0.5rem;
        }
        > input {
          padding-left: 2.5rem;
        }
      }
    }
  }

  .bt-header {
    .bt-toolbar {
      // margin-top: 1rem;
      min-height: 35px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      .left-panel {
        flex-grow: 1;
      }
      .left-panel {
        flex-grow: 1;
      }
      &.bt-toolbar-batch {
        // background: var(--primary-color);
        .bt-selected-label {
          color: var(--primary-color-text);
          padding-left: 1rem;
        }
      }
    }
  }

  .p-datatable .p-datatable-header {
    background: #f8f9fa;
    // background: #e0e0e0;
    color: #495057; /*aw*/
    border: 0.5px solid #787878; /*aw*/
    border-width: 0 0 0 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }

  .support-document-file-upload-error {
    color: var(--message-error-primary);
  }

  .support-document-file-upload-message {
    color: var(--message-success-primary);
  }

  .features-message-details {
    .pi-times {
      color: red;
    }

    .pi-check {
      color: green;
    }
  }
}
