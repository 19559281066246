@import "./../../../../../../style/utils/index.scss";

.main-user {
  .main-user-button {
    padding: 0.25rem;
    margin: 0.25rem 0.75rem;
  }

  .user-info {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;

    .avatar {
      width: 4rem;
      height: 4rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .p-tieredmenu {
    border: none;
    width: 100%;
  }

  .p-button-secondary {
    background: white;
    color: black !important;
    border: 0;
    // border-color: black !important;
    // border-radius: 50%;
  }
}

.main-user-panel {
  width: 240px;

  @include sm-screen {
    top: 69px !important;
  }

  &::before {
    display: none;
  }

  &::after {
    display: none;
  }
}

.avatar-sm {
  margin-top: 15px;
  max-width: 75px;
  max-height: 75px;
  border-radius: 50%;
}

.avatar-main {
  border-radius: 50%;
  object-fit: cover;
  object-position: 0% 0%;
  width: 48px;
  height: 48px;
  border: 2px solid var(--primary-color);
}

.avatar {
  text-align: center;
  font-weight: bold;
}

.info .p-tieredmenu {
  border: none;
  width: 100%;
}

.info .p-tieredmenu .p-menuitem {
  padding: 4px 0;
}

.fullname {
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0 15px 0;
  word-break: break-alls;
}

.main-user-panel.p-overlaypanel .p-overlaypanel-content {
  padding: 10px;

  .ep-login-container {
    .authentication-logout-button {
      width: 100%;
      display: flex;
      background: none;
      border: none;
      padding: 0 !important;
      text-decoration: none;
      color: #495057 !important;
      height: 3rem;
      font-size: 17px;

      &:hover {
        background-color: ActiveCaption;
      }

      .p-button-icon {
        display: block;
        font-size: 17px;
      }

      .p-button .p-button-icon-right {
        margin-left: 0rem;
        margin-right: 0.5rem;
      }
    }
  }
}

.info .p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  margin-right: 1rem;
  font-size: 20px;
}
